/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
}
@font-face {
  font-family: 'AnjaEliane';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/anja_eliane.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/anja_eliane.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/anja_eliane.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/anja_eliane.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/anja_eliane.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.AnjaEliane {
  font-family: AnjaEliane, sans-serif;
  font-weight: normal;
}
@font-face {
  font-family: 'Bjola';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.svg#bjola') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Bjola {
  font-family: Bjola;
  font-weight: normal !important;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
/*#endregion fonts*/
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
.outer-wrapper--alt .main-content__container {
  margin-top: 0px;
  padding-top: 0px;
}
.outer-wrapper--alt #BodyContent {
  padding-bottom: 50px;
}
.container {
  position: relative;
  /*@media (min-width: 1310px) {
		padding-left: 55px;
		padding-right: 55px;
	}*/
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
@media (max-width: 767px) {
  .header {
    padding: 80px 0 10px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 19.43%;
  }
}
.header__wrapper {
  padding-top: 10px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07a6ff), color-stop(65%, #057bff), to(#068bff));
  background-image: linear-gradient(#07a6ff, #057bff 65%, #068bff 100%);
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 23.94%;
    bottom: 7.11%;
    left: 37.98%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 0 auto 10px;
  }
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 23.9%;
    bottom: 59.41%;
    right: 1.06%;
  }
}
@media (max-width: 767px) {
  .header__call {
    display: block;
    margin: 0 auto 10px;
    width: 295px;
    height: 36px;
  }
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 25.61%;
    bottom: 35.56%;
    right: 0.89%;
  }
}
@media (max-width: 767px) {
  .header__phone {
    display: block;
    margin: 0 auto 10px;
  }
}
@media (min-width: 768px) {
  .header__social-intro {
    position: absolute;
    width: 23.58%;
    bottom: 43.44%;
    left: 1.85%;
  }
}
@media (max-width: 767px) {
  .header__social-intro {
    width: 291px;
    height: 34px;
  }
}
@media (max-width: 767px) {
  .header__social-links {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    width: 22.76%;
    bottom: 7.95%;
    left: 1.46%;
  }
}
@media (max-width: 767px) {
  .header__social-links {
    display: block;
    margin: 0 auto 10px;
  }
}
.header__social-link {
  display: inline-block;
  margin: 0 4px;
  text-align: center;
}
@media (min-width: 768px) {
  .header__social-link {
    width: 23%;
    margin: 0 0.7%;
  }
}
.header__social-link img {
  border-radius: 50%;
  border: 3px solid #fff;
  width: 45px;
  height: 45px;
}
.header .SearchPanel {
  position: absolute;
  bottom: 15%;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
  border-radius: 5px;
}
.header .SearchPanel:hover {
  -webkit-animation: boxShadowGlowWhite 1.5s;
  animation: boxShadowGlowWhite 1.5s;
}
.header .SearchPanel:hover input {
  padding-left: 1%;
}
.header .SearchPanel:hover:before {
  opacity: 0;
}
@media (max-width: 767px) {
  .header .SearchPanel {
    width: 80%;
    position: relative;
    display: inline-block;
    right: auto;
    margin: 30px auto;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: -webkit-box;
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .header .SearchPanel {
    bottom: 8%;
    right: 1%;
    width: 24.6%;
    height: 40px;
  }
}
@media (min-width: 1200px) {
  .header .SearchPanel {
    bottom: 12.9%;
  }
}
.header .SearchPanel input {
  border: none;
  border-radius: 5px;
  width: 100%;
  padding-left: 8%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .header .SearchPanel input {
    padding: 15px;
    padding-left: 8%;
  }
}
@media (min-width: 768px) {
  .header .SearchPanel input {
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .header .SearchPanel input {
    margin-left: 5px;
  }
}
.header .SearchPanel a {
  display: block;
  background-color: #0027a7;
  color: white;
  position: absolute;
  right: 1%;
  padding: 10px;
  height: 33px;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .header .SearchPanel a {
    right: 2%;
  }
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #2c5ca8;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
  display: none;
}
@media (min-width: 768px) {
  .toplinks {
    display: block;
    position: relative;
    padding: 0 15px;
  }
  .toplinks__wrapper {
    background: #000;
  }
  .toplinks__container {
    padding-left: 0;
    padding-right: 0;
  }
  .toplinks__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;
  }
  .toplinks__li {
    position: relative;
    list-style: none;
    text-align: center;
  }
  .toplinks__li .dropdown-toggle:focus {
    text-decoration: none;
  }
  .toplinks__link {
    position: relative;
    display: block;
    padding: 15px 5px;
    color: #fff;
    font-size: 2rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-family: AnjaEliane, sans-serif;
    font-weight: normal;
  }
  .toplinks__link:hover,
  .toplinks__link.active,
  .toplinks__link:focus {
    color: #ffe400;
  }
  .toplinks__dropdown {
    padding: 0;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    text-align: center;
    background-color: #000 !important;
  }
  .toplinks__dropdown-li {
    display: block;
  }
  .toplinks__dropdown-link {
    position: relative;
    padding: 5px 10px !important;
    font-size: 1.6rem;
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: 700 !important;
    color: #fff !important;
  }
  .toplinks__dropdown-link:hover,
  .toplinks__dropdown-link:focus,
  .toplinks__dropdown-link.active {
    background-color: inherit !important;
    color: #ffe400 !important;
  }
}
.banner {
  margin-top: 40px;
  border: 10px solid #fff;
  border-radius: 10px;
}
.banner #CarouselContainer {
  padding-top: 51.2%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__wrapper {
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/banner-bg.png');
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
  padding-bottom: 35px;
}
.banner__container {
  padding: 0;
}
.banner #CarouselContainer {
  max-height: none !important;
  max-width: none !important;
}
.banner .carousel img {
  width: 100% !important;
}
.widgets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.widgets__area-checker,
.widgets__facebook {
  width: 100%;
}
@media (min-width: 500px) {
  .widgets__area-checker,
  .widgets__facebook {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .widgets__area-checker,
  .widgets__facebook {
    width: 25%;
  }
}
.widgets__area-checker:before,
.widgets__facebook:before {
  content: "";
  display: block;
  padding-top: 41.333%;
}
.widgets__area-checker {
  background: #00a2ed;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.widgets__facebook {
  background: #0154A0;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
@media (min-width: 992px) {
  .widgets__facebook {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
.widgets__quick-checker {
  width: 100%;
  background: #812990;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  padding: 15px 30px;
}
@media (min-width: 501px) {
  .widgets__quick-checker {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .widgets__quick-checker {
    width: 50%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
@media (min-width: 501px) {
  .widgets__quick-checker:before {
    content: "";
    display: block;
    padding-top: 20.666%;
  }
}
.area-checker {
  position: relative;
}
.area-checker__title {
  position: absolute;
  width: 66%;
  left: 25.67%;
  top: 18.55%;
}
.area-checker__icon {
  position: absolute;
  width: 33.33%;
  left: 1.67%;
  top: 15.32%;
}
.area-checker #PostcodeSearchLeft select,
.area-checker #PostcodeSearchLeft input {
  position: absolute;
  width: 73.33%;
  left: 20%;
  top: 51.61%;
  height: 30%;
  padding-left: 15%;
}
@media (max-width: 767px), (min-width: 768px) {
  #HomepageGallery {
    display: none;
  }
}
@media (min-width: 1200px) {
  #HomepageGallery {
    display: block;
    position: relative;
    height: 160px;
    margin: 20px 0 20px;
  }
  #HomepageGallery:hover .Previous:after,
  #HomepageGallery:hover .Next:after {
    opacity: 0;
  }
  #HomepageGallery .Previous,
  #HomepageGallery .Next {
    display: block;
    width: 5%;
    height: 94%;
    position: absolute;
    background-size: 100% 100%;
    background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/banner-next-previous.png');
    -webkit-transition: background-image 0.1s linear;
    transition: background-image 0.1s linear;
  }
  #HomepageGallery .Previous:hover,
  #HomepageGallery .Next:hover {
    background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/banner-next-previous.png');
  }
  #HomepageGallery .Previous {
    left: 0;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  #HomepageGallery .Next {
    right: 0;
  }
  #HomepageGallery #HomepageGalleryOuter {
    top: 0;
    bottom: 0;
    left: 70px;
    right: 70px;
    overflow: hidden;
    position: absolute;
  }
  #HomepageGallery #HomepageGalleryInner {
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: -141px;
    right: -141px;
  }
  #HomepageGallery #HomepageGalleryInner div {
    float: left;
    width: 170px;
    height: 170px;
  }
  #HomepageGallery #HomepageGalleryInner div a {
    display: block;
    border-radius: 5px;
    overflow: hidden;
    width: 150px;
    height: 150px;
    padding: 0;
    margin: 0 auto;
    border: 2px solid #aaa;
  }
  #HomepageGallery #HomepageGalleryInner div a:hover {
    border-color: #C00;
  }
}
.quick-checker {
  position: relative;
  text-align: center;
}
.quick-checker__title {
  margin-bottom: 15px;
}
@media (min-width: 501px) {
  .quick-checker__title {
    margin-bottom: 0;
    position: absolute;
    width: 67.72%;
    left: 18.95%;
    top: 16.13%;
  }
}
.quick-checker__icon {
  display: none;
}
@media (min-width: 501px) {
  .quick-checker__icon {
    display: block;
    position: absolute;
    width: 12.81%;
    left: 4.21%;
    top: 16.13%;
  }
}
.quick-checker .SearchDate,
.quick-checker .SearchCategory,
.quick-checker .SearchButton {
  position: absolute;
  height: 30%;
}
.quick-checker .SearchDate {
  width: 30.18%;
  left: 14.91%;
  top: 51.61%;
  padding-left: 4%;
  border: none;
  border-radius: 5px;
}
.quick-checker .SearchCategory {
  width: 28.42%;
  left: 46.67%;
  top: 51.61%;
  border: none;
  border-radius: 5px;
  padding-left: 2%;
}
@media (max-width: 500px) {
  .quick-checker .SearchDate,
  .quick-checker .SearchCategory {
    height: 40px;
    width: 80%;
    display: block;
    position: relative;
    left: auto;
    top: auto;
    margin: 0 auto 15px;
  }
}
.quick-checker .SearchButton {
  color: #fff;
  font-family: Bjola;
  font-weight: normal !important;
  background-color: #9be006;
  background: -webkit-gradient(linear, left top, left bottom, from(#9be006), to(#58cb00));
  background: linear-gradient(to bottom, #9be006, #58cb00);
  border-radius: 5px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  font-size: 2rem;
  width: 20%;
  left: 76.67%;
  top: 51.61%;
}
@media (max-width: 500px) {
  .quick-checker .SearchButton {
    display: inline-block;
    position: relative;
    width: auto;
    top: auto;
    left: auto;
    padding: 8px 15px;
  }
}
.facebook {
  position: relative;
}
.facebook__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  width: 75%;
}
.castlePanel {
  width: 100%;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
  text-align: center;
}
.castlePanel .SingleImagePanel {
  height: auto;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
}
.castlePanel .castleCheckBook {
  background-color: #f40000;
  border-radius: 8px;
  font-size: 1.7rem;
  display: block;
  margin: 14px;
  padding-bottom: 6px;
  height: auto;
  font-family: Bjola;
  font-weight: normal !important;
}
.main-content__main-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main-content__left-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.main-content__container {
  margin-top: -100px;
  padding-top: 100px;
}
.main-content__wrapper {
  background-color: #29b7ed;
  background: -webkit-gradient(linear, left top, left bottom, from(#29b7ed), to(#4e8bf0));
  background: linear-gradient(to bottom, #29b7ed, #4e8bf0);
  position: relative;
}
.main-content__wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/chevrons-bg.png');
  opacity: 0.05;
  background-size: 30px;
}
.main-content__call {
  margin-top: 18px;
}
.main-content__products-intro {
  font-family: Bjola;
  font-weight: normal !important;
  text-align: center;
  color: #0786e9;
  font-size: 4.3rem;
}
.main-content__left-block {
  margin-top: auto;
  margin-bottom: 15px;
}
#PageListings {
  margin: 15px auto;
}
@media (min-width: 768px) {
  #PageListings {
    padding: 0 20px;
  }
}
@media (max-width: 400px) {
  #PageListings > div {
    width: 100%;
  }
}
.castlePanel {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
.pageContent h1 {
  font-family: Bjola;
  font-weight: normal !important;
  text-align: center;
  color: #0786e9;
  font-size: 4.6rem;
  margin-bottom: 22px;
}
.pageContent p {
  color: #000;
  font-size: 1.8rem;
  text-align: center;
}
.lower-content h2 {
  font-family: Bjola;
  font-weight: normal !important;
  text-align: center;
  color: #0786e9;
  font-size: 4.6rem;
  margin-bottom: 22px;
}
.lower-content p {
  color: #000;
  font-size: 1.8rem;
  text-align: center;
}
.category-links {
  margin-bottom: 60px;
  top: 26px;
  position: relative;
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  margin-top: 10px;
  text-align: left;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  text-shadow: 1px 1px 2px #000;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-size: 100% 100%;
  border-radius: 8px;
  padding: 12px 15px;
  overflow: hidden;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.5);
  font-size: 1.8rem;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.category-links__link:nth-of-type(5n + 1),
.category-links__child-link:nth-of-type(5n + 1) {
  background-color: #f12f7d;
  background: -webkit-gradient(linear, left top, left bottom, from(#f12f7d), to(#ee0161));
  background: linear-gradient(to bottom, #f12f7d, #ee0161);
}
.category-links__link:nth-of-type(5n + 1) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 1) + div .category-links__child-link {
  background-color: #f12f7d;
  background: -webkit-gradient(linear, left top, left bottom, from(#f12f7d), to(#ee0161));
  background: linear-gradient(to bottom, #f12f7d, #ee0161);
}
.category-links__link:nth-of-type(5n + 2),
.category-links__child-link:nth-of-type(5n + 2) {
  background-color: #eb7c1e;
  background: -webkit-gradient(linear, left top, left bottom, from(#eb7c1e), to(#e76100));
  background: linear-gradient(to bottom, #eb7c1e, #e76100);
}
.category-links__link:nth-of-type(5n + 2) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 2) + div .category-links__child-link {
  background-color: #eb7c1e;
  background: -webkit-gradient(linear, left top, left bottom, from(#eb7c1e), to(#e76100));
  background: linear-gradient(to bottom, #eb7c1e, #e76100);
}
.category-links__link:nth-of-type(5n + 3),
.category-links__child-link:nth-of-type(5n + 3) {
  background-color: #ffbb00;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffbb00), to(#ffaa00));
  background: linear-gradient(to bottom, #ffbb00, #ffaa00);
}
.category-links__link:nth-of-type(5n + 3) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 3) + div .category-links__child-link {
  background-color: #ffbb00;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffbb00), to(#ffaa00));
  background: linear-gradient(to bottom, #ffbb00, #ffaa00);
}
.category-links__link:nth-of-type(5n + 4),
.category-links__child-link:nth-of-type(5n + 4) {
  background-color: #40b83d;
  background: -webkit-gradient(linear, left top, left bottom, from(#40b83d), to(#17a913));
  background: linear-gradient(to bottom, #40b83d, #17a913);
}
.category-links__link:nth-of-type(5n + 4) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 4) + div .category-links__child-link {
  background-color: #40b83d;
  background: -webkit-gradient(linear, left top, left bottom, from(#40b83d), to(#17a913));
  background: linear-gradient(to bottom, #40b83d, #17a913);
}
.category-links__link:nth-of-type(5n + 5),
.category-links__child-link:nth-of-type(5n + 5) {
  background-color: #a951ab;
  background: -webkit-gradient(linear, left top, left bottom, from(#a951ab), to(#99339b));
  background: linear-gradient(to bottom, #a951ab, #99339b);
}
.category-links__link:nth-of-type(5n + 5) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 5) + div .category-links__child-link {
  background-color: #a951ab;
  background: -webkit-gradient(linear, left top, left bottom, from(#a951ab), to(#99339b));
  background: linear-gradient(to bottom, #a951ab, #99339b);
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  color: #fff;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
.testimonials__wrapper {
  position: relative;
  padding: 62px 0px;
}
.testimonials__wrapper:before,
.testimonials__wrapper:after {
  content: "";
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/testimonials-grass@1x.png');
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-size: auto 100%;
  background-repeat: repeat-x;
  z-index: 2;
}
.testimonials__wrapper:after {
  background-position: 77px 0px;
}
@media (max-width: 767px) {
  .testimonials__wrapper {
    padding: 15px 0px 0px;
  }
}
.testimonials__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonials__left-block {
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/starburst@1x.png');
  background-size: 100% auto;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .testimonials__left-block {
    background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/starburst@2x.png');
  }
}
@media (min-width: 768px) {
  .testimonials__left-block {
    -webkit-transform: translateY(44px);
            transform: translateY(44px);
  }
}
@media (min-width: 992px) {
  .testimonials__left-block {
    position: absolute;
    bottom: 0px;
    left: 0;
    -webkit-transform: translateY(44px);
            transform: translateY(44px);
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
}
.testimonials__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .testimonials__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.testimonials__left {
  position: relative;
}
@media (min-width: 992px) {
  .testimonials__left {
    width: 33.33%;
  }
}
@media (min-width: 992px) {
  .testimonials__main {
    width: 68%;
  }
}
.testimonials__outer {
  position: relative;
  padding: 20px 90px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .testimonials__outer {
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .testimonials__outer {
    padding-bottom: 0px;
  }
}
@media (min-width: 992px) {
  .testimonials__outer {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-bottom: 0;
    padding-bottom: 25px;
  }
}
.testimonials__title {
  font-family: Bjola;
  font-weight: normal !important;
  color: white;
  text-align: center;
  font-size: 5rem;
  max-width: 75% !important;
}
@media (max-width: 767px) {
  .testimonials__title {
    margin-bottom: 10px;
    max-width: 80% !important;
    font-size: 4rem;
  }
}
.testimonials .carousel {
  background: #fff;
  border-radius: 15px;
  padding: 20px 40px;
}
.testimonials .carousel-control.left,
.testimonials .carousel-control.right {
  top: 50%;
  bottom: auto;
  -webkit-transform: translateX(40%) translateY(-50%);
          transform: translateX(40%) translateY(-50%);
  opacity: 1;
  width: 10%;
  background-image: none;
  z-index: 1;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}
@media (max-width: 767px) {
  .testimonials .carousel-control.left,
  .testimonials .carousel-control.right {
    display: none;
  }
}
.testimonials .carousel-control.right {
  -webkit-transform: translateX(-40%) translateY(-50%);
          transform: translateX(-40%) translateY(-50%);
}
.testimonials .carousel-control.right img {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.testimonials a[itemprop="author"] {
  color: #ff8000;
  font-family: Bjola;
  font-weight: normal !important;
}
.testimonials a[itemprop="author"] + * {
  display: none;
}
.testimonials time {
  display: none;
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  text-align: center;
}
.testimonials .RotatorTestimonialContent > p:first-child {
  color: #fff;
}
.testimonials #RotatorTestimonialsHeader {
  margin-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader p,
.testimonials #RotatorTestimonialsHeader a {
  text-align: center;
  color: #fff;
  font-size: 2rem;
  position: relative;
}
.testimonials .RotatorTestimonialRating {
  max-width: 280px;
  margin: 0 auto;
}
.quick-form__wrapper {
  padding: 40px;
  background-color: #1e6bf5;
}
.contact {
  background: #1e6bf5;
  position: relative;
}
.contact:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQAgMAAABxSlcGAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAAA3RSTlMAgEC3KR0FAAAAQElEQVQI1z2MuREAMAjDBHtQslJqRs5IwS7i4zsEBuIi9XGrUs3Tg5BhTwqWQ+NCgU2d7cZPhS0aGwZjJ75yFw+hEwrDWcJjswAAAABJRU5ErkJggg==");
  opacity: 0.05;
}
.contact__heading {
  font-family: Bjola;
  font-weight: normal !important;
  color: #fff;
  font-size: 3.4rem;
  text-align: center;
}
@media (min-width: 992px) {
  .contact__heading {
    text-align: left;
  }
}
.contact-form .booking-form {
  border: none;
  margin: 0 auto;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
}
.contact-form .booking-form > div:first-child {
  display: none;
}
.contact-form .form-group {
  font-size: 0.9rem;
  margin-bottom: 20px;
}
.contact-form label {
  color: #fff;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.contact-form .form-control {
  border: none;
  border-radius: 0;
}
.contact-form .innerColumn > div:last-child > div {
  margin-left: 0;
  width: 100%;
  text-align: right;
}
.contact-form .innerColumn > div:last-child > div input {
  background-color: #9be006;
  background: -webkit-gradient(linear, left top, left bottom, from(#9be006), to(#58cb00));
  background: linear-gradient(to bottom, #9be006, #58cb00);
  color: #FFF;
  font-family: Bjola;
  font-weight: normal !important;
  text-transform: uppercase;
  font-size: 1.7875rem;
  line-height: 1;
  display: inline-block;
  width: auto;
  padding: 12px 30px;
  margin-top: 15px;
  border: none;
  border-radius: 10px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
}
.contact-social {
  margin: 70px auto;
}
@media (min-width: 992px) {
  .contact-social {
    margin: 0 auto;
  }
}
.contact-social__links {
  margin-top: 20px;
  margin-bottom: 57px;
  text-align: center;
}
@media (min-width: 992px) {
  .contact-social__links {
    text-align: left;
  }
}
.contact-social__link {
  display: inline-block;
  width: 15%;
  margin: 0 1%;
}
@media (max-width: 767px) {
  .contact-social__link {
    width: auto;
  }
  .contact-social__link img {
    width: 45px;
    height: 45px;
  }
}
.newsletter {
  max-width: 540px;
  margin: 0 auto;
}
.newsletter #NewsletterControl {
  text-align: right;
}
.newsletter #NewsletterControl .clear {
  display: none;
}
.newsletter label,
.newsletter input {
  display: block;
  text-align: left;
}
.newsletter label {
  color: #fff;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.newsletter input {
  height: 34px;
  width: 100%;
  border: none;
  margin-bottom: 25px;
}
.newsletter a {
  background-color: #9be006;
  background: -webkit-gradient(linear, left top, left bottom, from(#9be006), to(#58cb00));
  background: linear-gradient(to bottom, #9be006, #58cb00);
  color: #FFF;
  font-family: Bjola;
  font-weight: normal !important;
  text-transform: uppercase;
  font-size: 1.7875rem;
  line-height: 1;
  display: inline-block;
  width: auto;
  padding: 12px 30px;
  margin-top: 15px;
  border: none;
  border-radius: 10px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #2c5ca8;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer {
  background: #000;
  padding: 20px 0 50px;
  color: #a1a1a1;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .footer__text-center-sm {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__text-right-sm {
    text-align: right;
  }
}
.footer__links {
  text-align: center;
}
.footer__link {
  margin: 0 5px;
  color: #a1a1a1;
}
.footer__link:hover {
  color: #a1a1a1;
}
.footer #BCNLink {
  color: #a1a1a1;
}
.footer #BCNLink:hover {
  color: #a1a1a1;
}
