@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #2c5ca8;
@castleDetailsColor: #2c5ca8;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/037/";
//'url('@{cloudUrl}banner-bg.jpg'

/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400 !important;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700 !important;
}

.font-AnjaEliane;

.AnjaEliane {
	font-family: AnjaEliane,sans-serif;
	font-weight: normal;
}

.font-Bjola;

.Bjola {
	font-family: Bjola;
	font-weight: normal !important;
}

html {
}

body {
	.BodyFont;
}

.nicefont {
}

.textContent {
	h1 {
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--alt {
		.main-content__container {
			margin-top: 0px;
			padding-top: 0px;
		}

		#BodyContent {
			padding-bottom: 50px;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		width: 1170px;
	}
	/*@media (min-width: 1310px) {
		padding-left: 55px;
		padding-right: 55px;
	}*/
	&--content {
		flex-grow: 1;
	}
}

.header {
	@width: 1230px;
	@height: 239px;

	@media @mobile {
		padding: 80px 0 10px;
		text-align: center;
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__wrapper {
		padding-top: 10px;
		background-image: linear-gradient(#07a6ff,#057bff 65%,#068bff 100%);

		@media @mobile {
		}
	}

	&__logo {
		@media @tablet {
			position: absolute;
			width: 23.94%;
			bottom: 7.11%;
			left: 37.98%;
		}

		@media @mobile {
			display: block;
			margin: 0 auto 10px;
		}
	}

	&__call {
		.TopBarItem(294,@width,@height,auto,142,right,13);

		@media @mobile {
			display: block;
			margin: 0 auto 10px;
			width: 295px;
			height: 36px;
		}
	}

	&__phone {
		.TopBarItem(315,@width,@height,auto,85,right,11);

		@media @mobile {
			display: block;
			margin: 0 auto 10px;
		}
	}

	&__social-intro {
		@media @tablet {
			position: absolute;
			width: 23.58%;
			bottom: 43.44%;
			left: 1.85%;
		}

		@media @mobile {
			width: 291px;
			height: 34px;
		}
	}

	&__social-links {
		.TopBarItem(280,@width,@height,auto,19,left,18);

		@media @mobile {
			display: block;
			margin: 0 auto 10px;
		}
	}

	&__social-link {
		display: inline-block;
		margin: 0 4px;
		text-align: center;

		@media @sm {
			width: 23%;
			margin: 0 0.7%;
		}

		img {
			border-radius: 50%;
			border: 3px solid #fff;
			width: 45px;
			height: 45px;
		}
	}

	.SearchPanel {
		position: absolute;
		bottom: 15%;
		.flex-display();
		transition: all 0.3s;
		background-color: white;
		border-radius: 5px;

		&:hover {
			.animation(boxShadowGlowWhite 1.5s );

			input {
				padding-left: 1%;
			}

			&:before {
				opacity: 0;
			}
		}

		@media @mobile {
			width: 80%;
			position: relative;
			display: inline-block;
			right: auto;
			margin: 30px auto;
			.flex-display();
			.justify-content(space-between);
			.align-items(center);
		}

		@media @tablet {
			bottom: 8%;
			right: 1%;
			width: 24.6%;
			height: 40px;
		}

		@media @desktop {
			bottom: 12.9%;
		}

		input {
			border: none;
			border-radius: 5px;
			width: 100%;
			padding-left: 8%;
			transition: all 0.3s;

			@media @mobile {
				padding: 15px;
				padding-left: 8%;
			}

			@media @tablet {
				margin: 0 auto;
			}

			@media @desktop {
				margin-left: 5px;
			}
		}

		a {
			display: block;
			background-color: #0027a7;
			color: white;
			//.PTSansNarrowBold;
			position: absolute;
			right: 1%;
			padding: 10px;
			height: 33px;
			.FlexCenter();
			border-radius: 5px;
			text-transform: uppercase;
			top: 50%;
			.Transform(translateY(-50%));

			@media @mobile {
				right: 2%;
			}
		}
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
	display: none;

	@media @sm {
		display: block;
		position: relative;
		padding: 0 15px;

		&__wrapper {
			background: #000;
		}

		&__container {
			padding-left: 0;
			padding-right: 0;
		}

		&__nav {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 0;
			padding-left: 0;
		}

		&__li {
			position: relative;
			list-style: none;
			text-align: center;

			.dropdown-toggle:focus {
				text-decoration: none;
			}
		}

		&__link {
			position: relative;
			display: block;
			padding: 15px 5px;
			color: #fff;
			font-size: 2rem;
			transition: all 0.3s;
			.AnjaEliane;

			&:hover, &.active, &:focus {
				color: #ffe400;
			}
		}

		&__dropdown {
			padding: 0;
			margin: 0;
			border: none;
			box-shadow: none;
			text-align: center;
			background-color: #000 !important;
		}

		&__dropdown-li {
			display: block;
		}

		&__dropdown-link {
			position: relative;
			padding: 5px 10px !important;
			font-size: 1.6rem;
			.PTSansNarrowBold;
			color: #fff !important;

			&:hover, &:focus, &.active {
				background-color: inherit !important;
				color: #ffe400 !important;
			}
		}
	}
}

.banner {
	margin-top: 40px;
	border: 10px solid #fff;
	border-radius: 10px;
	.BannerPanelSet(51.2%);

	&__wrapper {
		background-image: url('@{cloudUrl}banner-bg.png');
		background-size: 100% 100%;
		position: relative;
		z-index: 1;
		padding-bottom: 35px;
	}

	&__container {
		padding: 0;
	}

	#CarouselContainer {
		max-height: none !important;
		max-width: none !important;
	}

	.carousel img {
		width: 100% !important;
	}
}

.widgets {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	&__area-checker, &__facebook {
		width: 100%;

		@media (min-width:500px) {
			width: 50%;
		}

		@media @md {
			width: 25%;
		}

		&:before {
			content: "";
			display: block;
			padding-top: 41.333%;
		}
	}

	&__area-checker {
		background: #00a2ed;
		order: 1;
	}

	&__facebook {
		background: #0154A0;
		order: 2;

		@media @md {
			order: 3;
		}
	}

	&__quick-checker {
		width: 100%;
		background: #812990;
		order: 3;
		padding: 15px 30px;

		@media (min-width:501px) {
			padding: 0;
		}

		@media @md {
			width: 50%;
			order: 2;
		}

		&:before {
			@media (min-width:501px) {
				content: "";
				display: block;
				padding-top: 20.666%;
			}
		}
	}
}

.area-checker {
	position: relative;
	@width: 300px;
	@height: 124px;

	&__title {
		position: absolute;
		width: round(percentage(198/@width),2);
		left: round(percentage(77/@width),2);
		top: round(percentage(23/@height),2);
	}

	&__icon {
		position: absolute;
		width: round(percentage(100/@width),2);
		left: round(percentage(5/@width),2);
		top: round(percentage(19/@height),2);
	}

	&__dropdown {
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input {
		position: absolute;
		width: round(percentage(220/@width),2);
		left: round(percentage(60/@width),2);
		top: round(percentage(64/@height),2);
		height: 30%;
		padding-left: 15%;
	}
}

#HomepageGallery {
	@media @mobile, @tablet {
		display: none;
	}

	@media @desktop {
		display: block;
		position: relative;
		height: 160px;
		margin: 20px 0 20px;

		&:hover {
			.Previous, .Next {
				&:after {
					opacity: 0;
				}
			}
		}

		.Previous, .Next {
			display: block;
			width: 5%;
			height: 94%;
			position: absolute;
			background-size: 100% 100%;
			background-image: url('@{cloudUrl}banner-next-previous.png');
			-webkit-transition: background-image .1s linear;
			transition: background-image .1s linear;

			&:hover {
				background-image: url('@{cloudUrl}banner-next-previous.png');
			}
		}

		.Previous {
			left: 0;
			.Transform(rotateY(180deg));
		}

		.Next {
			right: 0;
		}

		#HomepageGalleryOuter {
			top: 0;
			bottom: 0;
			left: 70px;
			right: 70px;
			overflow: hidden;
			position: absolute;
		}

		#HomepageGalleryInner {
			@Size: 120px;
			position: absolute;
			overflow: hidden;
			top: 0;
			bottom: 0;
			left: -141px;
			right: -141px;

			div {
				float: left;
				width: 170px;
				height: 170px;

				a {
					display: block;
					border-radius: 5px;
					overflow: hidden;
					width: 150px;
					height: 150px;
					padding: 0;
					margin: 0 auto;
					border: 2px solid #aaa;

					&:hover {
						border-color: #C00;
					}
				}
			}
		}
	}
}

.quick-checker {
	position: relative;
	@width: 570px;
	@height: 124px;
	text-align: center;

	&__title {
		margin-bottom: 15px;

		@media (min-width:501px) {
			margin-bottom: 0;
			position: absolute;
			width: round(percentage(386/@width),2);
			left: round(percentage(108/@width),2);
			top: round(percentage(20/@height),2);
		}
	}

	&__icon {
		display: none;

		@media (min-width:501px) {
			display: block;
			position: absolute;
			width: round(percentage(73/@width),2);
			left: round(percentage(24/@width),2);
			top: round(percentage(20/@height),2);
		}
	}

	.SearchDate, .SearchCategory, .SearchButton {
		position: absolute;
		height: 30%;
	}

	.SearchDate {
		width: round(percentage(172/@width),2);
		left: round(percentage(85/@width),2);
		top: round(percentage(64/@height),2);
		padding-left: 4%;
		border: none;
		border-radius: 5px;
	}

	.SearchCategory {
		width: round(percentage(162/@width),2);
		left: round(percentage(266/@width),2);
		top: round(percentage(64/@height),2);
		border: none;
		border-radius: 5px;
		padding-left: 2%;

		@media @md {
		}
	}

	.SearchDate, .SearchCategory {
		@media (max-width:500px) {
			height: 40px;
			width: 80%;
			display: block;
			position: relative;
			left: auto;
			top: auto;
			margin: 0 auto 15px;
		}
	}

	.SearchButton {
		color: #fff;
		.Bjola;
		.Gradient(#9be006,#58cb00);
		border-radius: 5px;
		text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
		.FlexCenter;
		text-transform: uppercase;
		font-size: 2rem;
		width: round(percentage(114/@width),2);
		left: round(percentage(437/@width),2);
		top: round(percentage(64/@height),2);

		@media (max-width:500px) {
			display: inline-block;
			position: relative;
			width: auto;
			top: auto;
			left: auto;
			padding: 8px 15px;
		}
	}
}

.facebook {
	position: relative;

	&__icon {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translateY(-50%) translateX(-50%);
		transform: translateY(-50%) translateX(-50%);
		width: 75%;
	}
}

.castlePanel {
	width: 100%;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	border: none;
	text-align: center;

	&:hover {
	}

	.SingleImagePanel {
		height: auto;
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			//font-size: 0.9rem;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		//font-size: 1rem;
		span.oldprice {
			//font-size: 0.65rem;
		}

		span.newprice {
			//font-size: 0.8rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		background-color: #f40000;
		border-radius: 8px;
		font-size: 1.7rem;
		display: block;
		margin: 14px;
		font-family: Bjola;
		font-weight: normal !important;
		padding-bottom: 6px;
		height: auto;
		.Bjola;
	}
}

.main-content {
	&__main-row {
		display: flex;
	}

	&__left-col {
		display: flex;
		flex-direction: column;
	}

	&__container {
		margin-top: -100px;
		padding-top: 100px;
	}

	&__wrapper {
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-position: center center;
			background-repeat: no-repeat;
			background-repeat: repeat;
			background-image: url('@{cloudUrl}chevrons-bg.png');
			opacity: .05;
			background-size: 30px;
		}

		background-color: #29b7ed;
		background: -webkit-gradient(linear,left top,left bottom,from(#29b7ed),to(#4e8bf0));
		background: linear-gradient(to bottom,#29b7ed,#4e8bf0);
		position: relative;
	}

	&__call {
		margin-top: 18px;
	}

	&__products-intro {
		.Bjola;
		font-weight: normal !important;
		text-align: center;
		color: #0786e9;
		font-size: 4.3rem;
	}

	&__left-block {
		margin-top: auto;
		margin-bottom: 15px;
	}
}

#PageListings {
	margin: 15px auto;

	@media @tablet {
		padding: 0 20px;
	}

	> div {
		@media (max-width:400px) {
			width: 100%;
		}
	}
}

.castlePanel {
	border: 1px solid #ccc;
	box-shadow: 0 3px 6px rgba(0,0,0,0.3);
}

.pageContent {
	h1 {
		.Bjola;
		font-weight: normal !important;
		text-align: center;
		color: #0786e9;
		font-size: 4.6rem;
		margin-bottom: 22px;
	}

	p {
		color: #000;
		font-size: 1.8rem;
		text-align: center;
	}
}

.lower-content {
	h2 {
		.Bjola;
		font-weight: normal !important;
		text-align: center;
		color: #0786e9;
		font-size: 4.6rem;
		margin-bottom: 22px;
	}

	p {
		color: #000;
		font-size: 1.8rem;
		text-align: center;
	}
}

.category-links {
	margin-bottom: 60px;
	top: 26px;
	position: relative;

	&__link, &__child-link {
		position: relative;
		display: block;
		margin-top: 10px;
		text-align: left;
		.PTSansNarrowBold;
		.TextShadow1();
		color: white;
		.Transition3s();
		background-size: 100% 100%;
		border-radius: 8px;
		padding: 12px 15px;
		overflow: hidden;
		text-shadow: 1px 2px 0 rgba(0,0,0,0.5);
		font-size: 1.8rem;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			border-left: 8px solid #fff;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:nth-of-type(5n + 1) {
			.Gradient(#f12f7d,#ee0161);

			+ div .category-links__child-link {
				.Gradient(#f12f7d,#ee0161);
			}
		}

		&:nth-of-type(5n + 2) {
			.Gradient(#eb7c1e,#e76100);

			+ div .category-links__child-link {
				.Gradient(#eb7c1e,#e76100);
			}
		}

		&:nth-of-type(5n + 3) {
			.Gradient(#ffbb00,#ffaa00);

			+ div .category-links__child-link {
				.Gradient(#ffbb00,#ffaa00);
			}
		}

		&:nth-of-type(5n + 4) {
			.Gradient(#40b83d,#17a913);

			+ div .category-links__child-link {
				.Gradient(#40b83d,#17a913);
			}
		}

		&:nth-of-type(5n + 5) {
			.Gradient(#a951ab,#99339b);

			+ div .category-links__child-link {
				.Gradient(#a951ab,#99339b);
			}
		}

		&:hover, &:focus, &.active {
			color: #fff;

			&:before {
				opacity: 1;
			}
		}
	}

	&__child-link {
		margin-left: 10px;
	}
}

.testimonials {
	&__wrapper {
		position: relative;
		padding: 62px 0px;

		&:before, &:after {
			content: "";
			background-image: url('@{cloudUrl}testimonials-grass@1x.png');
			position: absolute;
			bottom: 0;
			left: 0;
			height: 60px;
			width: 100%;
			background-size: auto 100%;
			background-repeat: repeat-x;
			z-index: 2;
		}

		&:after {
			background-position: 77px 0px;
		}

		@media @mobile {
			padding: 15px 0px 0px;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__left-block {
		background-image: url('@{cloudUrl}starburst@1x.png');

		@media @retinaMedia {
			background-image: url('@{cloudUrl}starburst@2x.png');
		}

		background-size: 100% auto;

		@media @sm {
			transform: translateY(44px);
		}

		@media @md {
			position: absolute;
			bottom: 0px;
			left: 0;
			transform: translateY(44px);
			transform: scale(1.4);
		}
	}

	&__container {
		display: flex;
		flex-direction: column-reverse;

		@media @md {
			flex-direction: row;
		}
	}

	&__left {
		position: relative;

		@media @md {
			width: 33.33%;
		}
	}

	&__main {
		@media @md {
			width: 68%;
		}
	}

	&__outer {
		position: relative;
		padding: 20px 90px;
		margin-bottom: 25px;

		@media @mobile {
			padding: 20px;
		}

		@media @sm {
			padding-bottom: 0px;
		}

		@media @md {
			.border-right-radius(50px);
			.border-left-radius(0);
			margin-bottom: 0;
			padding-bottom: 25px;
		}
	}

	&__title {
		.Bjola;
		color: white;
		text-align: center;
		font-size: 5rem;
		max-width: 75% !important;

		@media @mobile {
			margin-bottom: 10px;
			max-width: 80% !important;
			font-size: 4rem;
		}
	}

	.carousel {
		background: #fff;
		border-radius: 15px;
		padding: 20px 40px;
	}

	.carousel-control.left, .carousel-control.right {
		top: 50%;
		bottom: auto;
		transform: translateX(40%) translateY(-50%);
		opacity: 1;
		width: 10%;
		background-image: none;
		z-index: 1;
		filter: drop-shadow( 0 2px 4px rgba(0,0,0,0.2));

		@media @mobile {
			display: none;
		}
	}

	.carousel-control.right {
		transform: translateX(-40%)translateY(-50%);
	}

	.carousel-control.right img {
		transform: scaleX(-1);
	}

	a[itemprop="author"] {
		color: #ff8000;
		.Bjola;

		& + * {
			display: none;
		}
	}

	time {
		display: none;
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		text-align: center;

		& > p:first-child {
			color: #fff;
		}
	}

	#RotatorTestimonialsHeader {
		margin-bottom: 15px;

		p, a {
			text-align: center;
			color: #fff;
			font-size: 2rem;
			position: relative;
		}
	}

	.RotatorTestimonialRating {
		max-width: 280px;
		margin: 0 auto;
	}
}

.quick-form {
	&__wrapper {
		padding: 40px;
		background-color: #1e6bf5;
	}
}

.contact {
	background: #1e6bf5;
	position: relative;

	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQAgMAAABxSlcGAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAAA3RSTlMAgEC3KR0FAAAAQElEQVQI1z2MuREAMAjDBHtQslJqRs5IwS7i4zsEBuIi9XGrUs3Tg5BhTwqWQ+NCgU2d7cZPhS0aGwZjJ75yFw+hEwrDWcJjswAAAABJRU5ErkJggg==");
		opacity: 0.05;
	}

	&__heading {
		.Bjola;
		color: #fff;
		font-size: 3.4rem;
		text-align: center;

		@media @md {
			text-align: left;
		}
	}
}

.contact-form {
	.booking-form {
		border: none;
		margin: 0 auto;
		border-radius: 0;
		box-shadow: none;
		background-color: transparent;

		& > div:first-child {
			display: none;
		}
	}

	.form-group {
		font-size: 0.9rem;
		margin-bottom: 20px;
	}

	label {
		color: #fff;
		margin-bottom: 8px;
		text-transform: uppercase;
		font-size: 1.5rem;
	}

	.form-control {
		border: none;
		border-radius: 0;
	}

	.innerColumn {
		& > div:last-child > div {
			margin-left: 0;
			width: 100%;
			text-align: right;

			input {
				.Gradient(#9be006,#58cb00);
				color: #FFF;
				.Bjola;
				text-transform: uppercase;
				font-size: 1.7875rem;
				line-height: 1;
				display: inline-block;
				width: auto;
				padding: 12px 30px;
				margin-top: 15px;
				border: none;
				border-radius: 10px;
				text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
			}
		}
	}
}

.contact-social {
	margin: 70px auto;

	@media @md {
		margin: 0 auto;
	}

	&__links {
		margin-top: 20px;
		margin-bottom: 57px;
		text-align: center;

		@media @md {
			text-align: left;
		}
	}

	&__link {
		display: inline-block;
		width: 15%;
		margin: 0 1%;

		@media @mobile {
			width: auto;

			img {
				width: 45px;
				height: 45px;
			}
		}
	}
}

.newsletter {
	max-width: 540px;
	margin: 0 auto;

	#NewsletterControl {
		text-align: right;

		.clear {
			display: none;
		}
	}

	label, input {
		display: block;
		text-align: left;
	}

	label {
		color: #fff;
		margin-bottom: 8px;
		text-transform: uppercase;
		font-size: 1.5rem;
	}

	input {
		height: 34px;
		width: 100%;
		border: none;
		margin-bottom: 25px;
	}

	a {
		.Gradient(#9be006,#58cb00);
		color: #FFF;
		.Bjola;
		text-transform: uppercase;
		font-size: 1.7875rem;
		line-height: 1;
		display: inline-block;
		width: auto;
		padding: 12px 30px;
		margin-top: 15px;
		border: none;
		border-radius: 10px;
		text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}
// tidy up
.footer {
	background: #000;
	padding: 20px 0 50px;
	color: #a1a1a1;

	@media @mobile {
		text-align: center;
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__text-center-sm {
		@media @sm {
			text-align: center;
		}
	}

	&__text-right-sm {
		@media @sm {
			text-align: right;
		}
	}

	&__links {
		text-align: center;
	}

	&__link {
		margin: 0 5px;
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}

	#BCNLink {
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}
}
